import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import * as constants from '../config'

function ProjectList() {
    const navigate = useNavigate();
    const [projectList, setProjectList] = useState([]);
    const [filteredProjectList, setFilteredProjectList] = useState([]);
	const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
	const imageUrls = [
		"https://admin.unicorp.am/storage/products/January2024/2kffyW3b9YJqtzuDv5Gb.png",
		"https://admin.unicorp.am/storage/products/January2024/3sT1bQpmgRtVbYC9HvJ1.png",
		"https://admin.unicorp.am/storage/products/January2024/3VVOeczwR1JL1H7yzwtZ.png",
		"https://admin.unicorp.am/storage/products/January2024/5oiGYYlWTS8YBNLmw5hE.png",
		"https://admin.unicorp.am/storage/products/January2024/6OcytITZd0BYIBEwD2f7.png",
		"https://admin.unicorp.am/storage/products/January2024/7CM39ZXAuZ6ZOzjd80Se.png",
		"https://admin.unicorp.am/storage/products/January2024/8e1zuXaye5VAPgTcX0vI.png",
		"https://admin.unicorp.am/storage/products/January2024/8oDo2Nagj27uTOG4wdwG.png",
		"https://admin.unicorp.am/storage/products/January2024/AftJRmGFyrJctwmYWS9M.png",
		"https://admin.unicorp.am/storage/products/January2024/aOQvhnyU7mUqx6bCOZ4Z.png",
		"https://admin.unicorp.am/storage/products/January2024/apnouvRkz8xqPeLeayRJ.png",
		"https://admin.unicorp.am/storage/products/January2024/Bed71EynNP7fXOujCMoH.png",
		"https://admin.unicorp.am/storage/products/January2024/Bh5hvbNQTXhykIKMLN8Q.png",
		"https://admin.unicorp.am/storage/products/January2024/BzSXo2cWF1Yz4TSK0GdJ.png",
		"https://admin.unicorp.am/storage/products/January2024/c8XbkhGtMROOOih2UDa7.png",
		"https://admin.unicorp.am/storage/products/January2024/CgNwvQpqzORJ5VyVX6vQ.png",
		"https://admin.unicorp.am/storage/products/January2024/Ck5BowMVb18KAQYwSV4Y.png",
		"https://admin.unicorp.am/storage/products/January2024/cULEpxqTmWw1RT3OALeG.png",
		"https://admin.unicorp.am/storage/products/January2024/CYGKFBB78ZjeO6NUBzX4.png",
		"https://admin.unicorp.am/storage/products/January2024/Edy10WScszz8b9RnbJ4V.png",
		"https://admin.unicorp.am/storage/products/January2024/FhYVPHjNQXWvALEEy2xM.png",
		"https://admin.unicorp.am/storage/products/January2024/FKUT2ue5ubPiC7LhZPfq.png",
		"https://admin.unicorp.am/storage/products/January2024/fvRF4riNChavwffUsouj.png",
		"https://admin.unicorp.am/storage/products/January2024/HGCimjFgGyS675DuTFHY.png",
		"https://admin.unicorp.am/storage/products/January2024/ieq9lAs2PvHfh480KMls.png",
		"https://admin.unicorp.am/storage/products/January2024/iTvN9qJH1pej7VIxBfa6.png",
		"https://admin.unicorp.am/storage/products/January2024/joAfa55exhelArxrq7Pf.png",
		"https://admin.unicorp.am/storage/products/January2024/jVQumKm3SreKLn70oi09.png",
		"https://admin.unicorp.am/storage/products/January2024/K81RCzw2OUM8OFqUAZnF.png",
		"https://admin.unicorp.am/storage/products/January2024/LRdtPM2jvKPhlZcWCDne.png",
		"https://admin.unicorp.am/storage/products/January2024/MPjplOBE9qSF4gy5TxdZ.png",
		"https://admin.unicorp.am/storage/products/January2024/nhiO9wLKS9ggJl10jnEk.png",
		"https://admin.unicorp.am/storage/products/January2024/NrAC3ujSNgWfPCwEWs1P.png",
		"https://admin.unicorp.am/storage/products/January2024/oeIkuHxFpozuFmHn3K2y.png",
		"https://admin.unicorp.am/storage/products/January2024/ot8binPTnGtZp0Wu0qvA.png",
		"https://admin.unicorp.am/storage/products/January2024/POlEXJ0vcuZB2zCf65P3.png",
		"https://admin.unicorp.am/storage/products/January2024/pwTpLPxYu1nmGTFLKYEd.png",
		"https://admin.unicorp.am/storage/products/January2024/PxqTfTWSrORztaJWggDl.png",
		"https://admin.unicorp.am/storage/products/January2024/qJNHySxP828iCi2N6LLs.png",
		"https://admin.unicorp.am/storage/products/January2024/RjySGeMVpPw6VSWx5D6W.png",
		"https://admin.unicorp.am/storage/products/January2024/RKLQXmWNsgRzrHivSKFs.png",
		"https://admin.unicorp.am/storage/products/January2024/rwgqQN6Z62kEmIgWn26w.png",
		"https://admin.unicorp.am/storage/products/January2024/rX8EeYa4RJW2GhEA8Xe2.png",
		"https://admin.unicorp.am/storage/products/January2024/S5bf5UpYXcxqSWEhq6pH.png",
		"https://admin.unicorp.am/storage/products/January2024/Tgeur2ujuhKfte3XWvn1.png",
		"https://admin.unicorp.am/storage/products/January2024/tHvmmvVKvLy7Eu5Kd91z.png",
		"https://admin.unicorp.am/storage/products/January2024/tlpnjvj803kFzSjUMj3b.png",
		"https://admin.unicorp.am/storage/products/January2024/txx7WtjSUiVuQdKCyzkY.png",
		"https://admin.unicorp.am/storage/products/January2024/u0UgroJ7DvbavPkZHbKS.png",
		"https://admin.unicorp.am/storage/products/January2024/UC3lpvUVPg7brQtJ5IzJ.png",
		"https://admin.unicorp.am/storage/products/January2024/uW7N8ekRJ7oBnDGasywO.png",
		"https://admin.unicorp.am/storage/products/January2024/VZfmNM9xPgjfclpXyQdS.png",
		"https://admin.unicorp.am/storage/products/January2024/X5KE0uA8R8qKkCgJgdbK.png",
		"https://admin.unicorp.am/storage/products/January2024/xRCRwT9OIdPEC4NxpBj2.png",
		"https://admin.unicorp.am/storage/products/January2024/ZhWQznuKL3F9z76rP2gb.png"
	];

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
        fetchProjectList()
		preloadImages(imageUrls);
    }, [])

    const axiosInstance = axios.create({
		baseURL: constants.BASEURL,
    });
	
	const preloadImages=(array)=> {
		if (!preloadImages.list) {
			preloadImages.list = [];
		}
		var list = preloadImages.list;
		for (var i = 0; i < array.length; i++) {
			var img = new Image();
			img.onload = () => {
				var index = list.indexOf(this);
				if (index !== -1) {
					// remove image from the array once it's loaded
					// for memory consumption reasons
					list.splice(index, 1);
				}
			}
			list.push(img);
			img.src = array[i];
		}
	}
	const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
    const fetchProjectList = () => {
        axiosInstance.get('/orders',config)
            .then(function (response) {
                setProjectList(response.data.items);
				setFilteredProjectList(response.data.items);
            })
            .catch(function (error) {
				//Logout();
                console.log(error);
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/api/projects/${id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Project deleted successfully!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchProjectList()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }

    const Logout = () => {
        localStorage.removeItem("token");
        navigate("/");
    }
	
	const handleFilter = () => {
        let filteredProjects = projectList;

        if (startDate && endDate) {
            filteredProjects = filteredProjects.filter(
                (project) =>
                    new Date(project.created_at) >= new Date(startDate) &&
                    new Date(project.created_at) <= new Date(endDate)
            );
        }

        setFilteredProjectList(filteredProjects);
    };

    return (
        <Layout>
            <div className="container">
				<ul className="nav nav-tabs" role="tablist">
				<li className="nav-item" style={{width:'33%'}}>
				  <a className="nav-link active" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab1-tab"  >Պատվերներ</a>
				</li>
				<li className="nav-item" style={{width:'33%'}}>
				  <a className="nav-link " style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} href="/stores" id="tab2-tab"  >Խանութներ</a>
				</li>
				<li className="nav-item" style={{width:'33%'}}>
					  <a className="nav-link" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab3-tab" href="/payments" >Վճարումներ</a>
					</li>
			  </ul>        
                <h2 className="text-center mt-5 mb-3" style={{fontSize:'20px',fontWeight:'bold'}}>Պատվերների կառավարում</h2>
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-primary" to="/order/create">Գրանցել պատվեր</Link>
						<button onClick={() => Logout()} className="btn btn-outline-danger float-end"> Logout </button>
                    </div>
					<div className="nav">
						<div className="sm-4" style={{width:"35%"}}>
							<label htmlFor="startDate" className="form-label">
								Start Date:
							</label>
							<input
								style={{display:"block",width:"100%"}}
								type="date"
								className="form-control"
								id="startDate"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
							/>
						</div>
						<div className="sm-4" style={{width:"35%"}}>
							<label htmlFor="endDate" className="form-label">
								End Date:
							</label>
							<input
								style={{display:"block",width:"100%"}}
								type="date"
								className="form-control"
								id="endDate"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
							/>
						</div>
						<button onClick={handleFilter} style={{display:"block",width:"30%",height:"40px",marginTop:"32px"}} className="btn btn-primary sm-4">
							Filter
						</button>
					</div>
                    <div className="card-body"  style={{marginBottom:"100px",marginLeft:"-18px"}}>
						{/* Date filter */}
                        <table className="table table-bordered">
                            <thead>
                                <tr style={{fontSize:"13px"}}>
                                    <th>Խանութ</th>
                                    <th>Առաքում</th>
                                    <th>Ստեղծում</th>
                                    <th >-</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProjectList.map((project, key) => {
									if(!project.store){
										console.log(project);
									}
                                    return (
                                        <tr style={project.type==1?{fontSize:"11px",backgroundColor:"#cccccc"}:{fontSize:"11px"}} key={key}>
                                            <td>{project.store?project.store.name:project.id}</td>
                                            <td>{project.deliver_date}</td>
                                            <td>{project.created_at.split("T")[0]}</td>
                                            <td>
                                                <Link
                                                    to={`/order/show/${project.id}`}
                                                    className="btn btn-outline-info mx-1">
                                                    Show
                                                </Link>
                                                <Link
                                                    className="btn btn-outline-success mx-1"
                                                    to={`/order/edit/${project.id}`}>
                                                    Edit
                                                </Link>
												{/*<button
                                                    onClick={() => handleDelete(project.id)}
                                                    className="btn btn-outline-danger mx-1">
                                                    Delete
                                                </button>*/}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectList;