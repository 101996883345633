import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import ProductSelectionModal from "./ProductSelectionModal"
import Select from 'react-select';
import Modal from 'react-modal';
import './ProductSelectionModal.css';
import './refresh.css';
import * as constants from '../config'

function ProjecCreate() {
    const [date, setDate] = useState('');
    const [time, setTime] = useState('12։00')
    const [isSaving, setIsSaving] = useState(true)
	const [products, setProducts] = useState([]);
	const [stores, setStores] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [selectedSale, setSelectedSale] = useState('');
	const [sales, setSales] = useState('');
	const [cash, setCash] = useState(0);
	const [store, setStore] = useState('');
	const [type, setType] = useState(0);
	const [productNames, setProductaNmes] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [productsReq, setproductsReq] = useState([]);
	const [sum, setSum] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalImageSrc, setModalImageSrc] = useState({image:"",name:""});
	const [productGroups, setProductGroups] = useState([]);
	const [isPayment, setIsPayment] = useState(0);
	const [position, setPosition] = useState([]);
    const navigate = useNavigate();
	const [storeId, setStoreId] = useState(useParams().storeId || 0)
	
	
	
	const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
	const [showProductSelection, setShowProductSelection] = useState(false);

	const openProductSelection = () => {
		handleSearch();
		setShowProductSelection(true);
	};

	const closeProductSelection = () => {
		setShowProductSelection(false);
	};
    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
    });
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        } else{	
			
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setPosition([latitude, longitude]);
				},
				() => {
					alert('Unable to retrieve your location');
				}
			);
			axiosInstance.get('/stores',config)
            .then(function (response) {
				setStores(response.data.items);
				if(storeId){
					let selected = response.data.items.find((element) => element.id ==storeId);
					handleStoreChange({value:storeId.toString(),label:selected.name},response.data.items);
				}
				//setProductaNmes(response.data.items);
                //setProjectList(response.data.items);
            })
            .catch(function (error) {
                console.log(error);
            })	
			axiosInstance.get('/products',config)
            .then(function (response) {
				let dataOptions=[];
				response.data.items.map((item,index)=>{
					dataOptions.push({value: item.id, label: item.vendor_code+" - "+item.name+" - "+item.price+"֏", price:item.price})
				})
				setProductOptions(dataOptions);
				console.log(productNames);
				setProductaNmes(response.data.items);
				setIsSaving(false);
				//setProductaNmes(response.data.items);
                //setProjectList(response.data.items);
            })
            .catch(function (error) {
                console.log(error);
            })
			axiosInstance.get('/groups',config)
				.then(function (response) {
					setProductGroups(response.data.items);
				})
				.catch(function (error) {
					console.log(error);
				})
			axiosInstance.get('/sales',config)
				.then(function (response) {
					setSales(response.data.items);
				})
				.catch(function (error) {
					console.log(error);
				})
			
		}
		
    }, [])
	
	
	/*axiosInstance.interceptors.request.use(
	  (config) => {
		const token = localStorage.getItem('token')
		const auth = token ? `Bearer ${token}` : '';
		config.headers.common['Authorization'] = auth;
		return config;
	  },
	  (error) => Promise.reject(error),
	);*/
	
	const toggleModal = (src) => {
		setModalImageSrc(src);
        setIsModalOpen(!isModalOpen);
    };
	
	const Modal2 = ({ src }) => (
        <div className="modal" style={{ display: 'block',zIndex:999999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{modalImageSrc.name}</h5>
                        <button type="button" className="close" onClick={toggleModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <img src={modalImageSrc.image} alt="Product" style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
        </div>
    );

    const handleSave = () => {
		if(productsReq.length){
			setIsSaving(true);
			axiosInstance.post('/add-order', {
				store_id:store.value,
				deliver_date: date,
				type: type,
				deliver_time: null,
				isCash:cash?1:0,
				outputs:productsReq,
				payment:isPayment?1:0,
				lat:position[0],
				long:position[1],
			},config)
				.then(function (response) {
					Swal.fire({
						icon: 'success',
						title: 'Project saved successfully!',
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false);
					setTime('')
					setStore('')
					setDate('')
					setproductsReq([])
					setProductOptions([])
					setProductaNmes([])
					setProducts([])
					setSum(0);
					navigate("/orders");
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: 'An Error Occured!',
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		}else{
			Swal.fire({
					icon: 'error',
					title: 'An Error Occured!',
					showConfirmButton: false,
					timer: 1500
				})
		}
    }
	
	const handleAddProduct = () => {
		if(products.length){
			const currentProduct = products[products.length - 1];
			if (!currentProduct.productName || !currentProduct.productCount) {
			  // Display an alert or handle it in your preferred way
			  alert('Please fill in both product name and price before adding a new product.');
			  return;
			}
		}
		
    // Add a new product to the products list
    setProducts([...products, { productName: '', productCount: '',price:'' }]);
  };
  
  const number_format = (num) => {
	  return (Math.round(num * 100) / 100).toFixed(2);
  }
	const inputRefs = useRef({}); 

  const handleProductChange = (index, field, value, price=0) => {
    // Update the product data in the products list
    const updatedProducts = [...products];
    updatedProducts[index][field] = value;
	if(price){
		updatedProducts[index]["price"] = price;
	}
    setProducts(updatedProducts);
	let updatedProductsReq = [];
	let sum=0;
	updatedProducts.map((item)=>{
		if(item.productName){
			sum+=item.productCount?item.price*item.productCount:parseFloat(item.price)
		}
		if (item.productName && item.productCount) {
			updatedProductsReq.push({product_id:item.productName,count:item.productCount,discount:discount});
			setproductsReq(updatedProductsReq);
		}
	})
	setSum(sum*(1-discount/100));
	console.log(updatedProducts);
	
  };
  const handleStoreChange = (value,data=[]) => {
	
    setStore(value);
	let selected;
	if(data.length){
		selected = data.find((element) => element.id ==value.value);
	} else{
		selected = stores.find((element) => element.id ==value.value);
	}
	
	let updatedProductsReq = [];
	setDiscount(selected.discount);
	let sum=0;
	products.map((item)=>{
		sum+=item.count?item.price*item.count:parseFloat(item.price)
		updatedProductsReq.push({product_id:item.id,count:item.count,discount:discount});
	})
	setSum(sum*(1-selected.discount/100));
	setproductsReq(updatedProductsReq);
  };
  const handleProductSelection = (product) => {
    const existingProduct = selectedProducts.find((selected) => selected.id === product.id);
	setTimeout(()=>{
		const inputRef = inputRefs.current[product.id];
		if (inputRef) {
		  inputRef.select();
		  inputRef.focus();
		}
	},100)
	
    if (existingProduct) {
      handleProductCountChange(product.id, parseInt(existingProduct.count) + 1);
    } else {
      setSelectedProducts((prevSelected) => [...prevSelected, { ...product, count: 1 }]);
		
	}
	
  };

  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.id !== productId)
    );
  };

  const handleSelect = () => {
	let updatedProducts = [];
	let updatedProductsReq = [];
	let sum=0;
	selectedProducts.map((item)=>{
		updatedProducts.push({...item,discount:discount});
		updatedProductsReq.push({product_id:item.id,count:item.count,discount:discount});
		sum+=item.count?item.price*item.count:parseFloat(item.price)
	})
	console.log(selectedProducts);
	setProducts(updatedProducts);
	setproductsReq(updatedProductsReq);
	setSum(sum*(1-discount/100));
    setShowProductSelection(false);
  };

   
   const getProductCount = (productId) => {
    const selectedProduct = selectedProducts.find((product) => product.id === productId);
	console.log(selectedProduct);
    return selectedProduct ? selectedProduct.count : 0;
  };

  const handleSearch = (value,group=null) => {
	  let changedValue = value || ""
	  console.log("sdfsd",value);
	  console.log("23423",group);
	  let filteredProductsNew = productNames.filter((product) =>
		product.name.toLowerCase().includes(changedValue.toLowerCase()) || product.vendor_code.toLowerCase().includes(changedValue.toLowerCase())
	  );
	  if(group){
		  if(changedValue){
			  filteredProductsNew = filteredProductsNew.filter(product => {
				  return product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
					  (group ? product.group_id == group : true)
			  });
		  } else{
			  console.log(productNames)
			  filteredProductsNew = productNames.filter(product => {
				  return product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
					  (group ? product.group_id == group : true)
			  });
			  console.log(filteredProductsNew)
		  }


		 // console.log(filteredProductsNew);
	  }

	  setFilteredProducts(filteredProductsNew);
	  setSearchTerm(changedValue)
	  setSelectedGroup(group)
  }
  const handleProductCountChange = (productId, count) => {
	if(count>=1){
		let updatedProductsReq=[];
		let changeProds = [];
		  selectedProducts.map((item)=>{
			  if(item.id === productId){
				  item.count=count;
			  } 
			changeProds.push(item);  
			updatedProductsReq.push({product_id:item.id,count:item.count,discount:discount});
		  })
		  setSelectedProducts(changeProds);
		  setproductsReq(updatedProductsReq);
	}
	  
  };
  const storeOptions = stores.map(store => ({ value: store.id, label: store.name }));


    return (
        <Layout>
            <div className="container" style={{margin:"0",padding:"0"}}>
                <h2 className="text-center mt-5 mb-3">Գրանցել նոր պատվեր</h2>
                <div className="card">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/orders">Բոլոր Պատվերները
                        </Link>
                    </div>
                    <div className="card-body" style={{marginBottom:"100px"}}>
                        <form onSubmit={(e)=>{e.preventDefault();}}>
                            <div className="form-group">
								<div>
								  <label htmlFor="store" className="form-label">
									Խանութ
								  </label>
								  <Select
									options={storeOptions}
									onChange={handleStoreChange}
									value={store}
									isClearable
									placeholder="Որոնել Խանութ"
								  />
								</div>
								<label htmlFor="store" className="form-label">
									 Տեսակը
								  </label>
								  <select
									className="form-select"
									onChange={(e) => setType(e.target.value)}
									required
									value={type}
								  >
									<option value="0" >Պատվեր</option>
									<option value="1" >Վերադարձ</option>
								</select>
                            </div> 
							<div className="form-group">
                                <label htmlFor="date">Առաքման Ամիս, օր</label>
                                <input
                                    onChange={(event) => { setDate(event.target.value) }}
                                    value={date}
                                    type="date"
                                    className="form-control"
                                    id="date"
                                    name="date" />
                            </div>
							<div style={{marginBottom:"15px",marginTop:"10px"}} className="form-group">
                                <input
                                    onChange={(event) => { console.log(event.target.value);setCash(event.target.value) }}
                                    value={cash}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="cash"
                                    name="cash" />
                                <label className="form-check-label" htmlFor="cash">Կանխիկ</label>
                            </div>
							<div style={{marginBottom:"15px",marginTop:"10px"}} className="form-group">
                                <input
                                    onChange={(event) => { console.log(event.target.value);setIsPayment(event.target.value) }}
                                    value={isPayment}
                                    type="checkbox"
                                    className="form-check-input"
                                    id="cash1"
                                    name="cash1" />
                                <label className="form-check-label" htmlFor="cash1">Վճարում</label>
                            </div>
							{/*<div className="form-group">*/}
							{/*	<label htmlFor="group-select">Select Sale:</label>*/}
							{/*	<select*/}
							{/*		id="group-select"*/}
							{/*		className="form-control"*/}
							{/*		value={selectedSale}*/}
							{/*		onChange={(e) => setSelectedSale(e.target.value)}*/}
							{/*	>*/}
							{/*		<option value="">All Sales</option>*/}
							{/*		{sales.length && sales.map((sale) => (*/}
							{/*			<option key={sale.id} value={sale.id}>{sale.name}</option>*/}
							{/*		))}*/}
							{/*	</select>*/}
							{/*</div>*/}
							<div className="card-body" style={{margin:"0",padding:"0"}}>
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>Անուն</th>
											<th>Քանակ</th>
											<th>Գին</th>
											<th>-</th>
										</tr>
									</thead>
									<tbody>
										{products.map((item, key) => {
											return (
												<tr key={key}>
													<td>{item.vendor_code+"-"+item.name}</td>
													<td>{item.count}</td>
													<td>{number_format(item.price*(1-discount/100))+"֏"}</td>
													<td>{number_format(item.count*item.price*(1-discount/100))+"֏"}</td>
												</tr>
											)
										})}
										<tr key={"sds"}>
											<td></td>
											<td></td>
											<td></td>
											<td>{number_format(sum)+"֏"}</td>
										</tr>
									</tbody>
								</table>							
							</div>
							   <Modal
								isOpen={showProductSelection}
							   >
								<div className="container mt-3" style={{padding:0,margin:0}}>

									<div className="search-input-container mb-3" style={{backgroundColor:"#c2c2c2"}}>
										<div className="form-group">
											<label htmlFor="group-select">Select Group:</label>
											<select
												id="group-select"
												className="form-control"
												value={selectedGroup}
												onChange={(e) => handleSearch(searchTerm,e.target.value)}
											>
												<option value="">All Groups</option>
												{productGroups.map((group) => (
													<option key={group.id} value={group.id}>{group.name}</option>
												))}
											</select>
										</div>
									  <input
										type="text"
										className="form-control"
										placeholder="Search products"
										value={searchTerm}
										onChange={(e) => handleSearch(e.target.value,selectedGroup)}
									  />
									</div>
									<h2>Select Products</h2>
									<div className="list-group" style={{ marginBottom: "40px",marginTop: "60px"}}>
									  {filteredProducts.map((product) => (
										<div key={product.id} className="list-group-item" style={{padding:3,marginBottom:"10px",borderRadius:5,border:"1px solid #cccccc"}}>
										  <div className="row align-items-center">
											<div className="col-md-3 col-3 mb-3 mb-md-0">
											  <img
												src={product.image ? "https://admin.unicorp.am/storage/" + JSON.parse(product.image)[0] : null}
												
												className="img-thumbnail"
												style={{padding:0,margin:0}}
												 onClick={()=>toggleModal({image:product.image ? "https://admin.unicorp.am/storage/" + JSON.parse(product.image)[0] : null,name:product.name})}
											  />
											  
											</div>
											<div className="col-md-9 col-9">
											  <div className="d-flex justify-content-between">
												<h5 className="mb-1">{product.vendor_code + "-" + product.name}</h5>
												<span>{product.box}</span>
											  </div>
											  <p className="mb-1" style={{fontSize:"22px",textAlign:"right"}}><b>{product.price}֏</b></p>   
											</div>
											<div className="btn-group">
												<button
												  className={`btn btn-${selectedProducts.some((selected) => selected.id === product.id) ? 'light' : 'primary'}`}
												  onClick={() => handleProductSelection(product)}
												>
												  {selectedProducts.some((selected) => selected.id === product.id) ? '' : 'Ավելացնել'}
												</button>
												{selectedProducts.some((selected) => selected.id === product.id) && (
												  <div className="input-group">
													<button
													  className="btn btn-outline-secondary"
													  style={{ backgroundColor: "red", color: "white", height: "50px", width: "50px" }}
													  onClick={() => handleProductCountChange(product.id, parseInt(getProductCount(product.id)) - 1)}
													>
													  -
													</button>
													<input
													  ref={(el) => (inputRefs.current[product.id] = el)}
													  type="number"
													  className="form-control text-center"
													  style={{ height: "50px" }}
													  value={getProductCount(product.id)}
													  onChange={(e) => handleProductCountChange(product.id, e.target.value)}
													/>
													<button
													  className="btn btn-outline-secondary"
													  style={{ backgroundColor: "green", color: "white", height: "50px", width: "50px" }}
													  onClick={() => handleProductCountChange(product.id, parseInt(getProductCount(product.id)) + 1)}
													>
													  +
													</button>
													<button
													  className="btn btn-danger ms-2"
													  onClick={() => handleRemoveProduct(product.id)}
													>
													  Ջնջել
													</button>
												  </div>
												)}
											  </div>
										  </div>
										</div>
									  ))}
									  
									</div>
									
								  </div>
								  <div style={{ position: 'fixed',left:"0px", bottom: 0,width:"100%",textAlign:"center",zIndex:999, backgroundImage: "linear-gradient(transparent,#c2c2c2)", }}>
								  <button className="btn btn-primary mt-3" onClick={handleSelect} style={{marginBottom:"20px",backgroundColor:"green"}} >
									Հաստատել
								  </button>
								  </div>
								  {isModalOpen && <Modal2 />}
								</Modal>
							   <button
								  disabled={isSaving}
								  onClick={openProductSelection}
								  type="button"
								  className="btn btn-outline-primary mt-3"
								>
								  Ավելացնել Պրոդուկտ
								</button>
								<br />
								<br />
							<button
                                onClick={handleSave}
								disabled={isSaving}
                                type="button "
                                className="btn btn-primary mt-3">
                                Գրանցել Պատվեր
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjecCreate;