import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { CreditCard, CashStack, ArrowReturnLeft } from 'react-bootstrap-icons';
import axios from 'axios';
import Layout from "../components/Layout"
import * as constants from '../config'

function ProjectShow() {
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [project, setProject] = useState({})
    const [payments, setPayments] = useState([])
    const [orders, setOrders] = useState([])
    const [orderSum, setOrderSum] = useState(0)

    const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
    let sum=0;
    let sumOrder=0;
    let sumPay=0;
    let sumOrderTotal=0;
    let sumReturn=0;
    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
        //baseURL: 'http://192.168.86.110:8001/api',
    });
	
	function customSort(a, b) {
		// Extracting the dates from each item, falling back to a default if not present
		let dateA = a.deliver_date || a.payment_date;
		let dateB = b.deliver_date || b.payment_date;

		// Comparing the dates as strings (works well for YYYY-MM-DD format)
		if (dateA < dateB) return -1;
		if (dateA > dateB) return 1;
		return 0;
	}

    const number_format = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
    }

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/stores/${id}`,config)
            .then(function (response) {
                setProject(response.data.item)
            })
            .catch(function (error) {
                console.log(error);
            })
        axiosInstance.get(`/stores/${id}/payments`,config)
            .then(function (response) {
                
				setPayments(response.data.items)
				console.log("pay",response.data.items);
				let sumOrder = 0;
				axiosInstance.get(`/orders?storeId=${id}`,config)
					.then(function (response2) {

						sumOrder = 0;
						console.log("order",response2.data.items);
						response2.data.items.map((item,index)=>{
							axiosInstance.get(`/order/${item.id}`,config)
								.then(function (orderResponse) {
									sumOrder = 0;
									orderResponse.data.items.products.map((orderItem,x)=>{
										sumOrder += parseInt(orderItem.count)*parseFloat(orderItem.price)*(1-(parseInt(orderItem.discount)/100));
									})
									response2.data.items[index].amount = parseFloat(number_format(sumOrder));

								})
								.catch(function (error) {
									//Logout();
									console.log(error);
								})
						})
						setTimeout(()=>{
							let finishedData = response2.data.items.concat(response.data.items).sort(customSort);
							setOrders(finishedData);
						},3000)

					})
					.catch(function (error) {
						//Logout();
						console.log(error);
					})
				
            })
            .catch(function (error) {
                console.log(error);
            })

    }, [])
	//console.log(payments);
    return (
        <Layout>
            <div className="container">
                <h2 className="text-center mt-5 mb-3">Տեսնել Խանութ</h2>
                <div className="card">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/stores"> Խանութները
                        </Link>
						<Link to={"/order/create/"+id} className="btn btn-outline-danger float-end"> Գրանցել պատվեր </Link>
                    </div>
                    
                    <div style={{fontSize: "0.7rem", paddingLeft: 0}} className="card-body">
                        <h2> {project.name} </h2>
                        <table className="table table-bordered">
                            <thead>
                            <tr style={{fontSize:"13px"}}>
                                <th>Տեսակ</th>
                                <th>Ամիս/Օր</th>
                                <th >Պատ.</th>
                                <th >Վեր.</th>
                                <th >Վճ.</th>
                                <th >Ընդ.</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.length?orders.map((project, key) => {
								let OrderPrice = "-";
								let ReturnPrice = "-";
								let PayPrice = "-";
								if(project.type){
									ReturnPrice = -project.amount;
									sumReturn += project.amount;
									sumOrder-=project.amount; 
								}else{
									if(project.payment_date){
										sumPay+=parseFloat(project.price);
										PayPrice = -parseFloat(project.price);
										sumOrder-=parseFloat(project.price);
									} else{
										sumOrder+=project.amount;
										sumOrderTotal+=project.amount;
										OrderPrice = project.amount;
									}
									
								}
								
                                return (
                                    <tr style={project.payment_date?{fontSize:"11px",backgroundColor:"#2ed774",color:"white"}:(project.type ? {fontSize:"11px",backgroundColor:"#cccccc"}:{fontSize:"11px"})} key={key}>
                                        <td>
											{project.payment_date?
												project.order_id?
												<Link
														className="btn btn-outline-success mx-1"
														style={{color:"white",borderColor:"white"}}
														to={`/order/show/${project.order_id}`}>
														{project.payment_date ? <CreditCard /> : (project.type ? <ArrowReturnLeft /> : <CashStack />)}
												</Link>:<Link
														style={{color:"white",borderColor:"white"}}
														className="btn disabled btn-outline-success mx-1"
														>
														<CreditCard />
												</Link>
												:<Link
													className="btn btn-outline-success mx-1"
													to={`/order/show/${project.id}`}>
													{project.payment_date ? <CreditCard /> : (project.type ? <ArrowReturnLeft /> : <CashStack />)}
											</Link>}
                                        </td>
                                        <td>{project.deliver_date || project.payment_date}</td>
                                        <td>
                                            {OrderPrice}
                                        </td>
										<td>
                                            {ReturnPrice}
                                        </td>
										<td>
                                            {PayPrice}
                                        </td>
										<td>
                                            {sumOrder}
                                        </td>
                                    </tr>
                                )
                            }):""}
                            <tr>
                                <td colSpan="2"></td>
                                <td>{sumOrderTotal}</td>
                                <td>{sumReturn}</td>
                                <td>{sumPay}</td>
                                <td>{sumOrder}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
					<div className="card-body" >
						{Object.keys(project).length?Object.keys(project).map((item,index)=>(
							(Object.values(project)[index] && item!="legal_address" && item!="created_at" && item!="manager_id" && item!="updated_at" && item!="id"?
								<fragment key={index}>
									<b className="text-muted">{item}:</b>
									<p>{Object.values(project)[index]}</p>
								</fragment>
							:"")
						)):""}
                    </div>
                    {/*<div style={{fontSize: "0.7rem", paddingLeft: 0, marginBottom:"100px"}} className="card-body">
                        <h2> Վճարումներ </h2>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Ամիս/Օր</th>
                                <th>Պատվեր</th>
                                <th>Գին</th>
                            </tr>
                            </thead>
                            <tbody>
                            {payments?payments.map((item, key) => {
                                sum += parseFloat(item.price);
                                return (
                                    <tr key={key}>
                                        <td>{item.payment_date}</td>
                                        <td>{item.order_id?
                                            <Link
                                                className="btn btn-outline-success mx-1"
                                                to={`/order/show/${item.order_id}`}>
                                                Պատվեր {item.order_id}
                                            </Link>:"No Order"
                                        }</td>
                                        <td>{item.price}</td>
                                    </tr>
                                )
                            }):""}
                            <tr>
                                <td>Պատվերներ: {sumOrder}</td>
                                <td>Պարտք: {sumOrder-sum}</td>
                                <td>Վճարում: {sum}</td>
                            </tr>
                            </tbody>
					</table>
                    </div>*/}
                </div>
            </div>
        </Layout>
    );
}

export default ProjectShow;